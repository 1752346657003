import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { db } from "./Products/chemical";
import { collection, getDocs } from "firebase/firestore";

function Sitemap() {
  const [users, setUsers] = useState([]);
  const ChemicalData = collection(db, "Chemical");

  const [cosmetic, setCosmetics] = useState([]);
  const Cosmetics = collection(db, "Cosmetics");

  const [detergents, setDetergents] = useState([]);
  const Detergents = collection(db, "Detergents");

  const [foodaditive, setFoodAdditives] = useState([]);
  const Food_Additives = collection(db, "Food Additives");

  const [solvents, setSolvents] = useState([]);
  const Solvents = collection(db, "Solvents");

  useEffect(() => {
    const getFoodAddData = async () => {
      const foodAddData = await getDocs(Food_Additives);
      setFoodAdditives(
        foodAddData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    };
    getFoodAddData();
  }, []);

  useEffect(() => {
    const getSolventData = async () => {
      const solData = await getDocs(Solvents);
      setSolvents(solData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getSolventData();
  }, []);

  useEffect(() => {
    const getCosmeticsData = async () => {
      const cosData = await getDocs(Cosmetics);
      setCosmetics(cosData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getCosmeticsData();
  }, []);

  useEffect(() => {
    const getChemicalData = async () => {
      const data = await getDocs(ChemicalData);
      //   console.log(data);
      setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getChemicalData();
  }, []);

  useEffect(() => {
    const getDetergentData = async () => {
      const data = await getDocs(Detergents);
      //   console.log(data);
      setDetergents(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getDetergentData();
  }, []);

  //   -------------------------Search Filter Start-------------------------

  const [filter, setFilter] = useState("");
  const searchText = (event) => {
    setFilter(event.target.value);
  };

  let chemData = users.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key]
        .toString()
        .toLowerCase()
        .includes(filter.toString().toLowerCase())
    );
  });

  let CosmeticData = cosmetic.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key]
        .toString()
        .toLowerCase()
        .includes(filter.toString().toLowerCase())
    );
  });

  let deterData = detergents.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key]
        .toString()
        .toLowerCase()
        .includes(filter.toString().toLowerCase())
    );
  });

  let solData = solvents.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key]
        .toString()
        .toLowerCase()
        .includes(filter.toString().toLowerCase())
    );
  });

  let foodAddData = foodaditive.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key]
        .toString()
        .toLowerCase()
        .includes(filter.toString().toLowerCase())
    );
  });

  return (
    <div>
      <section className="mt-5 sitemap">
        <div className="container mt-5">
          <h1 className="main-heading text-center ">Sitemap</h1>
          <ul>
          <li>
              <h3><Link to="/">Home</Link></h3>
            </li>
            <li>
              <h3><Link to="/About">About Us</Link></h3>
            </li>
            <li>
              <h3><Link to="/Contact">Contact Us</Link></h3>
            </li>
            </ul>
          <ul style={{ lineHeight: "1.8"}}>
            
              <li><h3><Link to="/Products">Products</Link></h3></li>
              <div className="container row row-auto">
                <div className="col-lg-4 col-sm-6 col-md-4 col-6">
                  <li className="ml-3">
                    <HashLink to="/Products#Chemicals">
                      <span>Chemicals</span>
                    </HashLink>
                    <ul>
                      {chemData.map((item) => (
                        <li>
                          <HashLink to={"/Products#" + item.name}>
                            {item.name}
                          </HashLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                </div>
                <div className="col-lg-4 col-sm-6 col-md-4 col-6">
                  <li>
                    <HashLink smooth to="/Products#Cosmetics">
                      <span>Cosmetics</span>
                    </HashLink>
                    <ul>
                      {CosmeticData.map((item) => (
                        <li>
                          <HashLink to={"/Products#" + item.name}>
                            {item.name}
                          </HashLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                </div>
                <div className="col-lg-4 col-sm-6 col-md-4 col-6">
                  <li>
                    <HashLink smooth to="/Products#Solvents">
                      <span>Solvents</span>
                    </HashLink>
                    <ul>
                      {solData.map((item) => (
                        <li>
                          <HashLink to={"/Products#" + item.name}>
                            {item.name}
                          </HashLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                </div>
                <div className="col-lg-4 col-sm-6 col-md-4 col-6">
                  <li>
                    <HashLink smooth to="/Products#Detergents">
                      <span>Detergents</span>
                    </HashLink>
                    <ul>
                      {deterData.map((item) => (
                        <li>
                          <HashLink to={"/Products#" + item.name}>
                            {item.name}
                          </HashLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                  <li>
                    <HashLink smooth to="/Products#FoodChemicals">
                      <span>Food Chemicals</span>
                    </HashLink>
                    <ul>
                      {foodAddData.map((item) => (
                        <li>
                          <HashLink to={"/Products#" + item.name}>
                            {item.name}
                          </HashLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                </div>
              </div>
              <li>
              <h3><Link to="/Cart">Enquiry</Link></h3>
            </li>
          </ul>
         
        </div>
      </section>
    </div>
  );
}

export default Sitemap;
