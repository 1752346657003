const info=[
    {
        img:"./Applicants/Info-Cards/Assured.png",
        heading:"Assured Quality",
        content:"lorem The href attribute requires a valid value to be accessible"
    },
    {
        img:"./Applicants/Info-Cards/Delivery.png",
        heading:"All India Delivery",
        content:"lorem The href attribute requires a valid value to be accessible"
    },
    {
        img:"./Applicants/Info-Cards/Trust.png",
        heading:"Trust Worthy",
        content:"lorem The href attribute requires a valid value to be accessible"
    },
    {
        img:"./Applicants/Info-Cards/Transaction.png",
        heading:"Secure Transactions",
        content:"lorem The href attribute requires a valid value to be accessible"
    }
]

export default info;