import React, { useState, useEffect } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { getlocalItems } from "../Products/test";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Axios from "axios";
import sending from "./emailGIF/sending.gif";
import { Link, useHistory } from "react-router-dom";

function Enquiry() {
  let [list, setList] = useState(getlocalItems());

  useEffect(() => {
    const interval = setInterval(() => setList(getlocalItems()), 500);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const newList = [...new Set(list)];
  return (
    <Link
      class="nav-link fa-stack fa-2x has-badge"
      to="/Cart"
      data-count={newList.length}
    >
      Enquiry
      <img
      className="cart-img"
        src="./Applicants/carts.png"
        alt="cart"
      />
    </Link>
  );
}

function Cart() {
  // -------------------------Removing Items from List-------------------------

  let history = useHistory();
  const [data, setData] = useState(getlocalItems());
  function removeQuery(event) {
    toast.success("Item Removed from Enquiry List", { position: "top-center" });
    const rem = event.target.id;
    setData(data.filter((i) => i !== rem));
  }

  const newData = [...new Set(data)];

  useEffect(() => {
    localStorage.setItem("list", JSON.stringify(newData));
  }, [newData]);

  const [loading, setLoading] = useState(false);

  const [Query, setQuery] = useState({
    yourName: "",
    companyName: "",
    address: "",
    state: "",
    mobileNumber: "",
    email: "",
    queryList: newData,
    comments: "",
  });

  let name, value;
  const handleChange = (event) => {
    name = event.target.name;
    value = event.target.value;
    setQuery({ ...Query, [name]: value });
  };
  //------------------------------------- Mailer Setting -------------------------------------

  function sendQuery(e) {
    e.preventDefault();
    setLoading(true);
    const {
      yourName,
      companyName,
      address,
      state,
      mobileNumber,
      email,
      queryList,
      comments,
    } = Query;
    if (
      yourName &&
      companyName &&
      address &&
      mobileNumber &&
      email &&
      comments !== ""
    ) {
      const response = Axios.post("https://caishipolychem.herokuapp.com/mail", {
        yourName: yourName,
        companyName: companyName,
        address: address,
        state: state,
        email: email,
        mobileNumber: mobileNumber,
        queryList: queryList,
        comments: comments,
      })
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          history.push("/thankyou");
        })
        .catch((err) => {
          history.push("/Sorry");
          console.log(err);
        });
      if (response) {
        // alert(response);
        setQuery({
          yourName: "",
          companyName: "",
          address: "",
          mobileNumber: "",
          email: "",
          queryList: "",
          comments: "",
        });
      } else
      history.push("/Sorry");
        // toast.error(
        //   "There might be some error in sending mail, Please try Again after sometime"
        // );
    } else {
      setLoading(false)
      toast.error("Please fill data correctly", { position: "top-center" });
    }
  }

  //-------------------------------------For FireBase Setting -------------------------------------

  // const sendQuery = async (event) => {
  //   localStorage.clear();
  //   event.preventDefault();
  //   const { yourName, companyName, mobileNumber, email, queryList, comments } =
  //     Query;
  //   if (yourName && companyName && mobileNumber && queryList && email && comments  ) {
  //     const res = fetch(
  //       "https://newproject-fb691-default-rtdb.firebaseio.com/queryData.json",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({yourName,mobileNumber,companyName,email,queryList,comments}),
  //       }
  //     );
  //     if (res) {
  //       setQuery({
  //         yourName: "",companyName: "",mobileNumber: "",email: "",queryList: "",comments: ""});
  //         toast.info("Thanks For Sending Query ,We Will Contact You Soon", {
  //         position: "top-center",
  //       });
  //     } else {
  //       toast.error("Please fill the information Correctly");
  //     }
  //   } else {
  //     toast.error("Please fill data correctly", { position: "top-center" });
  //   }
  // };

  return (
    <div>
      {/* -----------------Front Section----------------- */}

      <section className="Front-Section text-center bg-light mt-5">
        <div class="col-md-5 p-lg-5 mx-auto">
          <h1 class="custom-heading mt-5">Enquiry Basket</h1>
        </div>
      </section>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#ED9524"
          fill-opacity="1"
          d="M0,160L48,154.7C96,149,192,139,288,117.3C384,96,480,64,576,69.3C672,75,768,117,864,144C960,171,1056,181,1152,160C1248,139,1344,85,1392,58.7L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>

      {/* -----------------Query List and Submittion Form----------------- */}

      <section>
        <div className="container">
          {/* -----------------Query List----------------- */}
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 md-5 pd-5 query-container "
              style={{ background: "#F0AA50",borderRadius:"50px" }}
            >
              <div className="container">
                <div className="container py-5 md-5">
                  <h4> Enquiry List</h4>
                  {newData.map((item) => {
                    return (
                      <div>
                        <div className="row">
                          <div className="col-7 mt-4">
                            <li id={item}>{item}</li>
                          </div>
                          <div className="col-5">
                            <button
                              onClick={removeQuery}
                              id={item}
                              type="button"
                              class="btn btn-outline-primary btn-rounded float-left"
                            >
                              Remove
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-trash"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* -----------------Submittion From----------------- */}
            <div className="col-lg-6 col-md-6 col-sm-12 px-3">
              {loading ? (
                <img src={sending} style={{ zIndex: "10" }} />
              ) : (
                <form style={{padding:"0 20px"}}>
                  <h4 className="my-5">Send Email</h4>
                  <div class="row form-outline">
                    <div className="col-md-3 col-sm-12">
                      <label
                        class="form-label mb-4 required"
                        for="form6Example1"
                      >
                        Your Name
                      </label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                      <input
                        type="text"
                        placeholder="Enter Your Name"
                        className="form-control"
                        name="yourName"
                        onChange={handleChange}
                        value={Query.yourName}
                      />
                    </div>
                  </div>

                  <div class="form-outline mb-4 row">
                    <div class="col-md-3">
                      <label class="form-label required" for="form6Example3">
                        Company name
                      </label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                      <input
                        placeholder="Enter Your Comapny Name"
                        type="text"
                        id="form6Example3"
                        class="form-control"
                        name="companyName"
                        value={Query.companyName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div class="form-outline mb-4 row">
                    <div class="col-md-3">
                      <label class="form-label required" for="form6Example4">
                        Address
                      </label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                      <input
                        placeholder="Enter Your Address"
                        type="text"
                        id="form6Example4"
                        class="form-control"
                        name="address"
                        value={Query.address}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div class="form-outline mb-4 row">
                    <div className="col-md-3">
                      <label class="form-label required" for="form6Example5">
                        Your Mobile Number
                      </label>
                    </div>
                    <div className="row col-md-9">
                      <div className="col-md-3 col-sm-3 col-3">
                        <input
                          type="text"
                          // id="form6Example4"
                          class="form-control"
                          value="+91"
                          readonly
                        />
                      </div>
                      <div className="col-md-9 col-sm-9 col-9">
                        <input
                          placeholder="Your Mobile Number (Max 10 Number)"
                          type="text"
                          id="form6Example5"
                          class="form-control"
                          name="mobileNumber"
                          value={Query.mobileNumber}
                          onChange={handleChange}
                          maxLength="10"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-outline mb-4 row">
                    <div className="col-md-3 ">
                      <label class="form-label required" for="form6Example6">
                        Select your state
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-12">
                      <select

                        value={Query.state}
                        name="state"
                        id="state"
                        class="form-select"
                        onChange={handleChange}
                      >
                        <option selected>-- Select State --</option>

                        <option value="Andaman and Nicobar Islands">
                          Andaman and Nicobar Islands
                        </option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh">
                          Arunachal Pradesh
                        </option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Dadar and Nagar Haveli">
                          Dadar and Nagar Haveli
                        </option>
                        <option value="Daman and Diu">Daman and Diu</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Puducherry">Puducherry</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">
                          Himachal Pradesh
                        </option>
                        <option value="Jammu and Kashmir">
                          Jammu and Kashmir
                        </option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                      </select>
                    </div>
                  </div>

                  <div class="form-outline mb-4 row">
                    <div className="col-md-3 ">
                      <label class="form-label required" for="form6Example7">
                        Your Email
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-12">
                      <input
                        placeholder="Enter a valid E-mail "
                        type="email"
                        id="form6Example7"
                        class="form-control"
                        name="email"
                        onChange={handleChange}
                        value={Query.email}
                      />
                    </div>
                  </div>

                  <div class="form-outline mb-4 row">
                    <div className="col-md-3">
                      <label class="form-label required" for="form6Example8">
                        Enter Your Requirement
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-12">
                      <textarea
                        class="form-control"
                        id="form6Example8"
                        rows="4"
                        value={Query.comments}
                        name="comments"
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-primary btn-block mb-4"
                    onClick={sendQuery}
                  >
                    Submit
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
}

export default Cart;
export { Enquiry };
