import React,{useState,useEffect} from 'react';
import { ToastContainer, toast } from "react-toastify";

import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { db } from '../chemical';
import { collection,getDocs } from 'firebase/firestore';
import Slider from "react-slick";
import NextBtn from './NextBtn';
import PreviousBtn from './PreviousBtn';
import {ArrowBackIos,ArrowForwardIos} from "@material-ui/icons";

const getlocalItems = () => {
  let listItems = localStorage.getItem("list");
  if (listItems) {
    return JSON.parse(listItems);
  } else {
    return [];
  }
};
function Feature() {
    const [filter, setFilter] = useState(""); 
    const [query, setQuery] = useState(getlocalItems());
    function handleClick(event) {
      const name = event.target.value;
      toast.info("Item Added to Enquiry List", { position: "top-center" });
      setQuery((query) => [...query, name]);
    }

    const [users, setUsers] = useState([]);
    const ChemicalData = collection(db, "Featured");
    useEffect(() => {
      localStorage.setItem("list", JSON.stringify(query));
    }, [query]);
    useEffect(() => {
        const getChemicalData = async () => {
          const data = await getDocs(ChemicalData);
          setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getChemicalData();
      }, []);
    

      let chemData = users.filter((item) => {
        return Object.keys(item).some((key) =>
          item[key]
            .toString()
            .toLowerCase()
            .includes(filter.toString().toLowerCase())
        );
      });
    
    const settings = {
        dots: true,
        infinite: true,
        autoplay:true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <NextBtn icon={ArrowForwardIos}/>,prevArrow: <PreviousBtn icon={ArrowBackIos} />
        
    };
  return (
    <div className='container feature px-5'>
        <Slider {...settings}>
        {chemData.map((item) => (
            <div className="col-lg-12 col-md-12 col-sm-6 col-6 px-1 text-center">
              <img className="productImages text-center px-" src={item.image} alt={item.name} />

              <div class="card mb-4 box-shadow">
                <div style={{backgroundColor:"#EDB367"}} class="card-header">
                  <h6 class="font-weight-normal">{item.name}</h6>
                </div>
                  <div className="card-body">
                   
                    <button
                      type="button"
                      class="btn btn-sm  btn-outline-primary"
                      onClick={handleClick}
                      value={item.name}
                    >
                      Add to Query List
                    </button>
                  </div>
              </div>
            </div>
          ))}
        </Slider>
        <ToastContainer/>
      </div>
  )
}

export default Feature