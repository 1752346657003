import {initializeApp} from "firebase/app";
import {getFirestore} from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "process.env.REACT_APP_API_KEY",
  authDomain: "testing-53b12.firebaseapp.com",
  projectId: "testing-53b12",
  storageBucket: "testing-53b12.appspot.com",
  messagingSenderId: "65460458041",
  appId: "1:65460458041:web:8b59c87edb0082767033e8"
};

const app=initializeApp(firebaseConfig);
export const db =getFirestore(app);