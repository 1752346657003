import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { db } from "./chemical";
import { collection, getDocs } from "firebase/firestore";

const getlocalItems = () => {
  let listItems = localStorage.getItem("list");
  if (listItems) {
    return JSON.parse(listItems);
  } else {
    return [];
  }
};

function Test() {
  const [query, setQuery] = useState(getlocalItems());
  function handleClick(event) {
    const name = event.target.value;
    toast.info("Item Added to Enquiry List", { position: "top-center" });
   setQuery((query) => [...query, name]);
  }

  useEffect(() => {
    localStorage.setItem("list", JSON.stringify(query));
  }, [query]);

  const [users, setUsers] = useState([]);
  const ChemicalData = collection(db, "Chemical");

  const [cosmetic, setCosmetics] = useState([]);
  const Cosmetics = collection(db, "Cosmetics");

  const [detergents, setDetergents] = useState([]);
  const Detergents = collection(db, "Detergents");

  const [foodaditive, setFoodAdditives] = useState([]);
  const Food_Additives = collection(db, "Food Additives");

  const [solvents, setSolvents] = useState([]);
  const Solvents = collection(db, "Solvents");

  useEffect(() => {
    const getFoodAddData = async () => {
      const foodAddData = await getDocs(Food_Additives);
      setFoodAdditives(
        foodAddData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    };
    getFoodAddData();
  }, []);


  useEffect(() => {
    const getSolventData = async () => {
      const solData = await getDocs(Solvents);
      setSolvents(solData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getSolventData();
  }, []);

  useEffect(() => {
    const getCosmeticsData = async () => {
      const cosData = await getDocs(Cosmetics);
      setCosmetics(cosData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getCosmeticsData();
  }, []);

  useEffect(() => {
    const getChemicalData = async () => {
      const data = await getDocs(ChemicalData);
      //   console.log(data);
      setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getChemicalData();
  }, []);

  useEffect(() => {
    const getDetergentData = async () => {
      const data = await getDocs(Detergents);
      //   console.log(data);
      setDetergents(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getDetergentData();
  }, []);

  //   -------------------------Search Filter Start-------------------------

  const [filter, setFilter] = useState("");
  const searchText = (event) => {
    setFilter(event.target.value);
  };

  let chemData = users.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key]
        .toString()
        .toLowerCase()
        .includes(filter.toString().toLowerCase())
    );
  });

  let CosmeticData = cosmetic.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key]
        .toString()
        .toLowerCase()
        .includes(filter.toString().toLowerCase())
    );
  });

  let deterData = detergents.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key]
        .toString()
        .toLowerCase()
        .includes(filter.toString().toLowerCase())
    );
  });

  let solData = solvents.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key]
        .toString()
        .toLowerCase()
        .includes(filter.toString().toLowerCase())
    );
  });

  
  let foodAddData = foodaditive.filter((item) => {
    return Object.keys(item).some((key) =>
      item[key]
        .toString()
        .toLowerCase()
        .includes(filter.toString().toLowerCase())
    );
  });


 

  // -------------------------Search Filter End-------------------------

  return (
    <div>
      <section
        style={{
          textAlign: "center",
        }}
      >
        <div className="container pd-5">
          <div>
            
              <h3 className="section-heading" style={{ width: "70%",color:"#D3400B" }}>
                Wide Range of Industrial Chemicals & Solvents
              </h3>
          </div>

          <input
            className="search-bar my-5"
            type="text"
            onChange={searchText.bind(this)}
            value={filter}
            placeholder="Search Here"
          />
        </div>
      </section>

      <div className="container">
        <h1 className="display-4 mb-5" id="Chemicals">
          Chemicals
        </h1>
        <div className="row">
          {chemData.map((item) => (
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <img className="product-images" src={item.image} alt={item.name} id={item.name}/>

              <div class="card mb-4 box-shadow">
                <div class="card-header">
                  <h6 class="font-weight-normal">{item.name}</h6>
                </div>
                  <div className="card-body">
                    <table
                      className="table table-borderless"
                      style={{ tableLayout: "fixed" }}
                    >
                      <tbody>
                        <tr>
                          <th style={{ wordWrap: "break-word" }}>
                            Packing Details
                          </th>
                          <td style={{ wordWrap: "break-word" }}>
                            {item.packDetail}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ wordWrap: "break-word" }}>
                            Physical state
                          </th>
                          <td style={{ wordWrap: "break-word" }}>
                            {item.state}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button
                      type="button"
                      class="btn btn-md btn-block btn-outline-primary"
                      onClick={handleClick}
                      value={item.name}
                    >
                      Add to Query List
                    </button>
                  </div>
              </div>
            </div>
          ))}
        </div>

        <h1 className="display-4 my-5" id="Cosmetics">
          Cosmetics
        </h1>
        <div className="row">
          {/* <h1>testing</h1> */}
          {CosmeticData.map((item) => (
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <img className="product-images" src={item.image} alt={item.name} id={item.name}/>

              <div class="card mb-4 box-shadow">
                <div class="card-header">
                  <h6 class="font-weight-normal">{item.name}</h6>
                </div>
                  <div className="card-body">
                    <table
                      className="table table-borderless"
                      style={{ tableLayout: "fixed" }}
                    >
                      <tbody>
                        <tr>
                          <th style={{ wordWrap: "break-word" }}>
                            Packing Details
                          </th>
                          <td style={{ wordWrap: "break-word" }}>
                            {item.packDetail}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ wordWrap: "break-word" }}>
                            Physical State
                          </th>
                          <td style={{ wordWrap: "break-word" }}>
                            {item.state}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button
                      type="button"
                      class="btn btn-md btn-block btn-outline-primary"
                      onClick={handleClick}
                      value={item.name}
                    >
                      Add to Query List
                    </button>
                  </div>
              </div>
            </div>
          ))}
        </div>

        <h1 className="display-4 my-5" id="Detergents">
          Detergents
        </h1>
        <div className="row">
          {/* <h1>testing</h1> */}
          {deterData.map((item) => (
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <img className="product-images" src={item.image} alt={item.name} id={item.name} />

              <div class="card mb-4 box-shadow">
                <div class="card-header">
                  <h6 class="font-weight-normal">{item.name}</h6>
                </div>
                  <div className="card-body">
                    <table
                      className="table table-borderless"
                      style={{ tableLayout: "fixed" }}
                    >
                      <tbody>
                        <tr>
                          <th style={{ wordWrap: "break-word" }}>
                            Packing Details
                          </th>
                          <td style={{ wordWrap: "break-word" }}>
                            {item.packDetail}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ wordWrap: "break-word" }}>
                            Physical State
                          </th>
                          <td style={{ wordWrap: "break-word" }}>
                            {item.state}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button
                      type="button"
                      class="btn btn-md btn-block btn-outline-primary"
                      onClick={handleClick}
                      value={item.name}
                    >
                      Add to Query List
                    </button>
                  </div>
              </div>
            </div>
          ))}
        </div>

     
        <h1 className="display-4 my-5" id="FoodAdditives">
          Food Additives
        </h1>
        <div className="row">
          {foodAddData.map((item) => (
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <img className="product-images" src={item.image} alt={item.name} id={item.name}/>

              <div class="card mb-4 box-shadow">
                <div class="card-header">
                  <h6 class="font-weight-normal">{item.name}</h6>
                </div>
                  <div className="card-body">
                    <table
                      className="table table-borderless"
                      style={{ tableLayout: "fixed" }}
                    >
                      <tbody>
                        <tr>
                          <th style={{ wordWrap: "break-word" }}>
                            Packing Details
                          </th>
                          <td style={{ wordWrap: "break-word" }}>
                            {item.packDetail}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ wordWrap: "break-word" }}>
                            Physical State
                          </th>
                          <td style={{ wordWrap: "break-word" }}>
                            {item.state}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button
                      type="button"
                      class="btn btn-md btn-block btn-outline-primary"
                      onClick={handleClick}
                      value={item.name}
                    >
                      Add to Query List
                    </button>
                  </div>
              </div>
            </div>
          ))}
        </div>
        <h1 className="display-4 my-5" id="Solvents">
          Solvents
        </h1>
        <div className="row">
          {solData.map((item) => (
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <img className="product-images" src={item.image} alt={item.name} id={item.name}/>

              <div class="card mb-4 box-shadow">
                <div class="card-header">
                  <h6 class="font-weight-normal">{item.name}</h6>
                </div>
                  <div className="card-body">
                    <table
                      className="table table-borderless"
                      style={{ tableLayout: "fixed" }}
                    >
                      <tbody>
                        <tr>
                          <th style={{ wordWrap: "break-word" }}>
                            Packing Details
                          </th>
                          <td style={{ wordWrap: "break-word" }}>
                            {item.packDetail}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ wordWrap: "break-word" }}>
                            Physical State
                          </th>
                          <td style={{ wordWrap: "break-word" }}>
                            {item.state}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button
                      type="button"
                      class="btn btn-md btn-block btn-outline-primary"
                      onClick={handleClick}
                      value={item.name}
                    >
                      Add to Query List
                    </button>
                  </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Test;
export { getlocalItems };
