import React from "react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./footer.css";


const date = new Date().getFullYear();

const Footer = () => {
  const scrollToTop =()=>{
    window.scrollTo({
      top:0,
      behavior:"smooth"
    })
  }
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path fill="#EF972D" fill-opacity="1" d="M0,288L80,261.3C160,235,320,181,480,186.7C640,192,800,256,960,261.3C1120,267,1280,213,1360,186.7L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
</svg> 
      <section className="footer-container">
      <footer className="container">
     
        
        <div className="row row-auto">
          <div className="col-lg-3 col-md-6 col-sm-6 col-6 footer-section mt-5 text-center">
            <Link to="/" onClick={scrollToTop}>
              <img className="footer-image d-flex flex-column mt-2" alt="Caishi Polychem" src="./Applicants/CaishiPolychem.png"/>
              <img className="footLogo mt-3" src="./Applicants/foot-logo3.png" alt="Caishi Polychem"/>
            </Link>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-6 footer-section mt-5">
            <h3 className="ml-5 mb-4 footer-heading">Our Chemicals</h3>
            <ul class="footer-list">
              
              
              <li>
                <HashLink smooth to="/Products#Chemicals">Chemicals</HashLink>
              </li>
              <li>
                <HashLink smooth to="/Products#Cosmetics">Cosmetics</HashLink>
              </li>
              <li>
                <HashLink smooth to="/Products#Solvents">Solvents</HashLink>
              </li>
              <li>
                <HashLink smooth to="/Products#Detergents">Detergents</HashLink>
              </li>
              
              <li>
                <HashLink smooth to="/Products#FoodChemicals">Food Chemicals</HashLink>
              </li>
              
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-6 footer-section mt-5">
            <h3 className="mb-4  footer-heading">Our Company</h3>
            <ul class="footer-list">
              <li>
                <Link to="/About" onClick={scrollToTop}>About Us</Link>
              </li>
              <li>
                <Link to="/Products" onClick={scrollToTop}>Our Products</Link>
              </li>
              <li>
                <Link to="/Contact" onClick={scrollToTop}>Contact Us</Link>
              </li>
              <li>
                <Link to="/Cart" onClick={scrollToTop}>Enquiry</Link>
              </li>
              <li>
                <Link to="/Sitemap" onClick={scrollToTop}>Sitemap</Link>
              </li>

            </ul>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-6 footer-section mt-5">
            <h3 className="mb-4 footer-heading">Contact Info</h3>
            <ul class="footer-list ">
              <li><a href="https://goo.gl/maps/BH6Er6qAqYNiXums7">
                F-71/4, Rajiv Gandhi Road, Pakki Wali Gali No.8
                Swaroop Nagar,Near Dutta Mandir ,Delhi-110042 
                </a>
              </li>
              <li>Email : <a  target="_blank" href = "https://mail.google.com/mail/u/0/#inbox?compose=CllgCJTNHtGsxGZJnbnTtdZzCLNQBmxmbGjhHLJNkMwbbgRthjtpPDpLBjmPhWtvBsVhLgZCCXq">caishipolychem@outlook.com</a></li>
              <li>
                Moblie Numbers : 
                <br/>+91 99995-76733<br/>
                +91 99993-39454
                <br />
                +91 98919-03806
              </li>
            </ul>
          </div>
          <div className="row row-auto">
            <div className="col-lg-6 col-md-6 ">
              <ul className="footer-legal">
                <li>
                  <Link to="/privacy_policy" onClick={scrollToTop}>Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms_and_conditions" onClick={scrollToTop}>Terms &amp; Conditions</Link>
                </li>
              
              </ul>
            </div>
            
          </div>
          <p className="text-center my-5 copy-text">
            Copyright ©{date} CaishiPolychems.
            <br /> All rights reserved. Content may not be copied without
            written permission.
          </p>
        </div>
      </footer>
      </section>
    </div>
  );
};

export default Footer;
