import React from "react";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Card from "./Card";
import App_detail from "./Detail";

function Applicants() {
  return (
    <div className="container">
       <div className="row my-5">

            {App_detail.map((detail) => (
              <div className="col-lg-4 col-md-6 col-sm-4 col-6 my-3 px-2" style={{textAlign:"center"}}>
                  <img className="img-logo" src={detail.src} alt="service" />
                  <Card
                  key={detail.id}
                  name={detail.name}
                  //   meaning={detail.meaning}
                />
              </div>
            ))}
            
          
        </div>
     
    </div>
  );
}

export default Applicants;
