import React from "react";
import Test from "./test";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { Link } from "react-router-dom";


export default function Product() {
  
  return (
    <div className="mt-5">
      <section
        className="Front-Section"
        style={{
          textAlign: "center",
        }}
      >
      <i class="fas fa-shopping-cart"></i>

        <div className="container mt-5" style={{ textAlign: "center" }}>
          <h2 className="custom-heading my-5">Categories</h2>
          <ul>
            <a className="res-cat"
            style={{marginLeft:"0"}}
              href="#Chemicals"
            >
              Chemicals
            </a>
            <a className="res-cat"
              href="#Cosmetics"
            >
              Cosmetics
            </a>
            <a className="res-cat"
              href="#Solvents"
            >
              Solvents
            </a>
            <a className="res-cat"
              href="#Detergents"
            >
              Detergents
            </a>
            <a className="res-cat"
              href="#FoodAdditives"
            >
              Food Additives
            </a>
            
          </ul>
        </div>
      </section>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#ED9524"
          fill-opacity="1"
          d="M0,160L48,154.7C96,149,192,139,288,117.3C384,96,480,64,576,69.3C672,75,768,117,864,144C960,171,1056,181,1152,160C1248,139,1344,85,1392,58.7L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <Link to="/cart"><img alt="cart" className="proceed" src="./Applicants/carts.png"/> </Link>
      <a href="https://wa.me/919540885858"><img alt="Whatsapp Caishi polychem" className="proceed-whatsapp" src="./Applicants/whatsapp.png"/> </a>


      <Test />
    </div>
  );
}

