import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useHistory} from "react-router-dom";

const Contact = () => {
  const [contact, handleContact] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    address: "",
    email: "",
    phone: "",
    query: "",
  });

  let history=useHistory();

  let name, value;
  const handleChange = (event) => {
    name = event.target.name;
    value = event.target.value;
    console.log(event.target.value);
    handleContact({ ...contact, [name]: value });
  };

  // handle inputs
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { firstName, lastName, companyName, address, email, phone, query } =
      contact;
    if (firstName && lastName && companyName && address && email && phone) {
      const res = fetch(
        "https://caishi-poly-default-rtdb.firebaseio.com/contactData.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firstName,
            lastName,
            companyName,
            address,
            email,
            phone,
            query,
          }),
        }
      ).then((res)=>{
        history.push("/thankyou");
      })
      .catch((err)=>{
        history.push("/Sorry");
        console.log(err)
      })
      if (res) {
        handleContact({
          firstName: "",
          lastName: "",
          companyName: "",
          address: "",
          email: "",
          phone: "",
          query: "",
          sent: false,
        });
        
        // toast.success("Thanks for contacting us,We will reply you soon", {
        //   position: "top-center",
        // });
      } else {
        toast.error("plz fill the data");
      }
    } else {
      toast.error("Please Enter The Required Information Carefully", {
        position: "top-center",
      });
    }
  };

  // End of handle imputs

  return (
    <div>
      <section className="Front-Section mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center pr-5 mr-5 ">
              <h1 className="main-heading mt-5">Contact Us</h1>
              <p className="about-text" style={{ width: "85%" }}>
                We operate in an industry built on trust and relationships. At
                <b> CAISHI POLYCHEM </b>, we would be delighted to hear from
                you. Your feedback, enquiries, proposals and requests for
                technical assistance, sample quotations is what we look forward
                to. Don’t be a stranger, simply say Hi or Namaste!
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 text-center">
              <img
                style={{ width: "44vw" }}
                alt="homeImage"
                src="./Applicants/Contact.png"
              />
            </div>
          </div>
        </div>
      </section>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#ED9524"
          fill-opacity="1"
          d="M0,160L48,154.7C96,149,192,139,288,117.3C384,96,480,64,576,69.3C672,75,768,117,864,144C960,171,1056,181,1152,160C1248,139,1344,85,1392,58.7L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <h2 className="custom-heading section-heading">
          Contact Information
        </h2>
      <section className="mb-5">
        <div className="container mb-5">
          <div className="row">
          <div class="col-lg-4 col-md-4 col-sm-4 col-4">
              <h4 className="table-heading mx-5 mb-5 text-center"><b>Email</b></h4>
              <table className="mx-auto">
                <tbody className="text-center">
                  <tr>
                    <td><a  target="_blank" href = "https://mail.google.com/mail/u/0/#inbox?compose=DmwnWrRvwLzXTpzwGLGbvhfgmhBcWKjwfdwvxVCvFVqrGnHGSwwLsnxmgfvVTSFMcPcdvjVZfGPl">caishi.polychem@gmail.com</a></td>
                  </tr>
                  <tr>
                    <td><a  target="_blank" href = "https://mail.google.com/mail/u/0/#inbox?compose=GTvVlcSHvpDjdDzCrVpsXVbmPKMLhvjjNJhTMSLBbFtSkncZcxFWbmwgVJPHXsrLGlfZJqPCFdCQh">caishipolychem@outlook.com</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
              <h4 className="table-heading text-center mb-5"><b>Regd. Office & Godown</b></h4>
              <table className="mx-auto">
                <tbody className="text-center">
                  <tr>
                    <td>F-71/4, Rajiv Gandhi Road,<br/> Pakki Wali Gali No.8 Swaroop Nagar,<br/>Near Dutta Mandir,<br/>Delhi-110042</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
              <h4 className="table-heading text-center mb-5"><b>Working Hours</b></h4>
              <table className="mx-auto">
                <tbody>
                  <tr>
                    <td>Monday</td>
                    <td>10:00 AM to 06:30 PM</td>
                  </tr>
                  <tr>
                    <td>Tuesday</td>
                    <td>10:00 AM to 06:30 PM</td>
                  </tr>
                  <tr>
                    <td>Wednesday</td>
                    <td>10:00 AM to 06:30 PM</td>
                  </tr>
                  <tr>
                    <td>Thursday</td>
                    <td>10:00 AM to 06:30 PM</td>
                  </tr>
                  <tr>
                    <td>Friday</td>
                    <td>10:00 AM to 06:30 PM</td>
                  </tr>
                  <tr>
                    <td>Saturday</td>
                    <td>10:00 AM to 06:30 PM</td>
                  </tr>
                  <tr>
                    <td>Sunday</td>
                    <td>Closed</td>
                  </tr>
                  
                </tbody>
              </table>
              
            </div>
            
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div
              style={{ background: "#F0AA50",borderRadius:"25px" }}
              className="col-lg-6 col-md-6 col-sm-12 "
            >
              <h2 className="px-3 pt-3">Message Us</h2>
              <h5 className="px-3">We are delighted to hear from you.</h5>
              <form className="mx-3 p-3" method="POST">
                <div class="row mb-4">
                  <div class="col">
                    <div class="form-outline">
                      <label class="form-label required" for="form6Example1">
                        First name
                      </label>
                      <input
                        placeholder="First Name"
                        type="text"
                        id="form6Example1"
                        class="form-control"
                        name="firstName"
                        value={contact.firstName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-outline">
                      <label class="form-label required" for="form6Example2">
                        Last name
                      </label>
                      <input
                        type="text"
                        id="form6Example2"
                        class="form-control"
                        name="lastName"
                        value={contact.lastName}
                        onChange={handleChange}
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-outline mb-4  required">
                  <label class="form-label" for="form6Example3">
                    Company name
                  </label>
                  <input
                    type="text"
                    id="form6Example3"
                    class="form-control"
                    name="companyName"
                    value={contact.companyName}
                    onChange={handleChange}
                    placeholder="Company Name"
                  />
                </div>

                <div class="form-outline mb-4  required">
                  <label class="form-label" for="form6Example4">
                    Address
                  </label>
                  <input
                    type="text"
                    id="form6Example4"
                    class="form-control"
                    name="address"
                    value={contact.address}
                    onChange={handleChange}
                    placeholder="Address"
                  />
                </div>

                <div class="form-outline mb-4  required">
                  <label class="form-label" for="form6Example5">
                    Email
                  </label>
                  <input
                    type="email"
                    id="form6Example5"
                    class="form-control"
                    name="email"
                    value={contact.email}
                    onChange={handleChange}
                    required
                    placeholder="Your_Email@domain.com"
                  />
                </div>

                <div class="form-outline mb-4  required">
                  <label class="form-label" for="form6Example6">
                    Phone
                  </label>
                  <input
                    type="number"
                    id="form6Example6"
                    class="form-control"
                    name="phone"
                    value={contact.phone}
                    onChange={handleChange}
                    maxLength="10"
                    placeholder="Your Mobile number"
                  />
                </div>

                <div class="form-outline mb-4">
                  <label class="form-label" for="form6Example7">
                    Suggestion (Optional)
                  </label>
                  <textarea
                    class="form-control"
                    id="form6Example7"
                    rows="4"
                    name="query"
                    value={contact.query}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  class="btn btn-primary btn-block mb-4"
                  onClick={handleSubmit}
                >
                  Send
                </button>
              </form>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center my-5 ">
              <iframe
                className="gmap"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d338.1624761461425!2d77.1574670196804!3d28.756540886012512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe77702e67d439573!2zMjjCsDQ1JzIzLjYiTiA3N8KwMDknMjYuOCJF!5e1!3m2!1sen!2sin!4v1645523365271!5m2!1sen!2sin"
                style={{ loading: "lazy" }}
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <a target="_blank" href="https://wa.me/919540885858">
        <img
          alt="whatsapp"
          className="proceed-whatsapp"
          src="./Applicants/whatsapp.png"
        />
      </a>

      <ToastContainer />
    </div>
  );
};

export default Contact;
