import React from "react";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div>
      <section >
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center pr-5 mr-5 ">
              <h1 className="main-heading">Oops !!!</h1>
              <p style={{width:"75%"}}>
              We're sorry, the page you were looking for isn't found here. The link you followed may either be broken or no longer exists. Please try again, or take a look at our.
              </p>

              <Link to="/"><button type="submit" class="btn btn-primary">
                  GO TO HOMEPAGE
                </button></Link> 

            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 text-center">
              <img
                class="mt-5 pt-5"
                style={{width:"44vw"}}
                alt="errorImage"
                src="./Applicants/404.jpg"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Error;
