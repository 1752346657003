import React from "react";
import info from "./info";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../../node_modules/bootstrap/dist/js/bootstrap.bundle";

export default function Infocard() {
  return (
    <div>
      <div class="row">
        {info.map((infos) => {
          return (
            <div class="col-lg-3 col-md-6 col-sm-6 col-6" style={{textAlign:"center"}}>
              <img
                class="img-logo"
                src={infos.img}
                alt="services"
              />
              
              <h2 class="card-heading">{infos.heading}</h2>
              {/* <p class="card-text"><Fade top cascade>{infos.content}</Fade>
                
              </p> */}
            </div>
          );
        })}
      </div>
    </div>
  );
}
