import React from "react";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { Enquiry } from "./Cart/Cart";
import {Link} from "react-router-dom";


const NavBar = () => {

  const scrollToTop =()=>{
    window.scrollTo({
      top:0,
      behavior:"smooth"
    })
  }

  return (
    <div>
    <div>
      <nav class="navbar navbar-expand-lg navbar-light fixed-top navigation-bar">
        <div class="container-fluid">
          <Link class="navbar-brand" to="/" onClick={scrollToTop}>
            <img className="nav-logo" src="./Applicants/navlogo.jpg" alt="caishi polychem navigation logo"/>
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse navmen" id="navbarSupportedContent">
            <ul class="navbar-nav  mb-2 mb-lg-0 " >
              <li class="nav-item">
                <Link class="nav-link active" onClick={scrollToTop}  to="/">
                  Home
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" onClick={scrollToTop} to="/About">
                  About Us
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" onClick={scrollToTop} to="/Contact">
                  Contact Us
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" onClick={scrollToTop} to="/Products">
                  Products
                </Link>
              </li>
              <li class="nav-item">
                <Enquiry />
              </li>

                           
            </ul>
            
          </div>
        </div>
      </nav>
    </div>
    </div>
  );
};

export default NavBar;