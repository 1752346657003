import React from "react";
import { Link } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

function Sorry() {
  return (
    <div>
      <section className="container">
        <div className="container mt-5 d-flex ">
          <div className="row mt-5">
            <div className="col-lg-12 d-flex flex-column text-center justify-content-center pr-5 mr-5 ">
              <h1 className="main-heading mt-5">
                We are very Sorry
              </h1>
              <p className=" text-center greeting">
                But it seems like there is an error in Sending mail, We request you to try again after some time
              </p>
              <hr className="my-5" />
            </div>
           
            <p className="text-center">
              Having trouble? <Link to="/contact">Contact us</Link>
            </p>
            <p class="lead text-center">
            <Link to="/"><button type="submit" class="btn btn-primary">
                  GO TO HOMEPAGE
                </button></Link> 
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Sorry;
