import React from "react";
import { Link } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Feature from "../Products/feature/Feature";
import Infocard from "./Infocards/Infocard";
const Home = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      {/* -----------------Front Section----------------- */}
      <img src="Applicants/Home.jpeg" className="home" alt="home page" />
      <div className="mt-5">
        <div className="firstbox container main px-5">
          <div className="px-5 d-flex flex-column justify-content-center align-middle ">
            <h2 class="high-light-tag mt-5" top cascade>
              Welcome To
            </h2>
            <h1 class="highlight" top cascade>
              Caishi Polychem
            </h1>
            <div class="exp-btn">
              <button class="btn btn-light cstm">
                <Link to="/Products">
                  <a>Explore More</a>
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#ED9524"
          fill-opacity="1"
          d="M0,160L48,154.7C96,149,192,139,288,117.3C384,96,480,64,576,69.3C672,75,768,117,864,144C960,171,1056,181,1152,160C1248,139,1344,85,1392,58.7L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>

      {/* -----------------Information Cards----------------- */}
      <section class="md-5">
        <h2 class="custom-heading section-heading">
          Create a Customer Relationship that last forever
        </h2>
        <div class="container card-container">
          <Infocard />
        </div>
      </section>
      {/* -----------------About Section----------------- */}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#EDB367"
          fill-opacity="1"
          d="M0,288L80,261.3C160,235,320,181,480,186.7C640,192,800,256,960,261.3C1120,267,1280,213,1360,186.7L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        ></path>
      </svg>

      <section className="section-height about-section">
        <div className="container my-5">
          <div className="container">
            <div className="row mt-5 align-items-left">
              <div className="col-lg-8 col-md-8 col-sm-12 col-12 p-5 about-section-back">
                <h2 className="custom-heading my-5 homeAboutText">
                  About Our Company
                </h2>
                <p className="mt-5 homeAboutText about-text">
                  Distributor and Marketing Agent for Chemicals and raw
                  materials for a variety of industries
                </p>

                <p className="homeAboutText about-text">
                  Incepted in the year 2006, Caishi Polychem is emerged amongst
                  the prominent trader and wholesaler of a superb quality
                  collection of Solvent Chemicals, Food Chemicals, PVC Resin,
                  Sodium Metabisulphite, Super Absorbent Polymer, Fumed Silica,
                  Tcca 90 Granular, Sodium Percarbonate, Sodium
                  Dichloroisocyanurate and Synthetic Iron Oxide Pigments. All
                  the provided chemicals are formulated with utmost care by the
                  use of highest quality chemical compounds and progressive
                </p>
                <button class="btn btn-light cstm mb-5">
                  <Link onClick={scrollToTop} to="/About">
                    <a>Read More</a>
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#EDB367"
          fill-opacity="1"
          d="M0,160L48,154.7C96,149,192,139,288,117.3C384,96,480,64,576,69.3C672,75,768,117,864,144C960,171,1056,181,1152,160C1248,139,1344,85,1392,58.7L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      {/* -----------------Product Category Information----------------- */}
      <section>
        <h2 className="custom-heading section-heading">Hot Products </h2>
        <Feature />
      </section>
      <a target="_blank" href="https://wa.me/919540885858">
        <img
          alt="whatsapp"
          className="proceed-whatsapp"
          src="./Applicants/whatsapp.png"
        />
      </a>
    </div>
  );
};

export default Home;
