import React from "react";
import "./CssAbout.css";
import "../Home/Applications and market/Applicants";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";


const About = () => {
  return (
    <div>
      <section className="Front-Section mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center ">
              <h1 className="main-heading mt-5">About Us</h1>
              <h4 style={{color:"black"}}className="high-light-tag">The Story of Our Company</h4>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 text-center">
<img
                style={{width:"44vw"}}
                alt="Caishi Polychem about"
                src="./Applicants/About.png"
              />
            </div>
          </div>
        </div>
      </section>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#ED9524"
          fill-opacity="1"
          d="M0,160L48,154.7C96,149,192,139,288,117.3C384,96,480,64,576,69.3C672,75,768,117,864,144C960,171,1056,181,1152,160C1248,139,1344,85,1392,58.7L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <section>
        <div className="container">
          <h1 className="headings">Our Story</h1>
          <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12">
              
              <p className="about-text">
                Incepted in the year 2006, Caishi Polychem is emerged amongst
                the prominent trader and wholesaler of a superb quality
                collection of Solvent Chemicals, Food Chemicals, PVC Resin,
                Sodium Metabisulphite, Super Absorbent Polymer, Fumed Silica,
                Tcca 90 Granular, Sodium Percarbonate, Sodium
                Dichloroisocyanurate and Synthetic Iron Oxide Pigments. All the
                provided chemicals are formulated with utmost care by the use of
                highest quality chemical compounds and progressive techniques at
                our vendors’ end. The complete offered range is widely cherished
                and demanded by our patrons for their accurate composition, long
                shelf life, high effectiveness, safe packaging, balanced pH
                value and non-toxic nature. Apart from this, we ensure to
                deliver these chemicals in finest quality packaging materials
                within the specified period of time.
              </p>
              <p className="about-text">
                With the help of our skilled procuring agents, we are associated
                with some of the genuine and esteemed vendors of the industry.
                Our vendors formulate the offered products in conformity with
                industry laid guidelines and standards. Due to the support of
                our capable and talented professionals, we have been able to
                accomplish the bulk orders of our clients within the specified
                period of time. In order to ensure the safe delivery of these
                products, we provide our products in finest quality packaging
                materials.
              </p>
              <p className="about-text">
                Our expert quality controllers stringently check the offered
                range against several parameters of quality to ensure its
                precision and purity. Under the guidance and support of our
                mentor, Mr. Sudhir Awasthi, we are able to achieve highest
                satisfaction of our patrons in an efficient manner.
              </p>
              
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12 text-center ">
              <img
                className="portfolio mt-5 pt-5"
                alt="Caishi Polychem"
                src="./Applicants/portfolios.jpg"
              />

            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container my-5 why-us ">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12 my-auto text-center">
              <img
                className="whyus-img"
                alt="whyUs"
                src="./Applicants/Whyus.png"

              />
            </div>

            <div className="col-lg-7 col-md-7 col-sm-12 d-flex flex-column justify-content-center">
            <h1 className="headings my-5">WHY CAISHI POLYCHEM?</h1>
              <p className="about-text">
                Being a client-centric organization, we are indulged in
                providing superior quality products to our patrons within the
                stipulated period of time. We have gained the trust and faith of
                our respected clients by providing the products as per their
                specific needs and requirements. Owing to our cost effective
                prices, ethical business practices and quality approved
                products, we have achieved a remarkable place in this domain.
                Some other factors of our firm are mentioned below:
              </p>
              <ul className="why-list">
                <li>Certified vendors</li> 
                <li>Trained professionals</li>
                <li>Market leading rates</li>
                <li>Rigorous quality checks</li>
                <li>Transparent dealings</li>
                <li>Easy modes of payment </li>
              </ul>

            </div>
          </div>
        </div>
      </section>
      <a target="_blank" href="https://wa.me/919540885858">
        <img
          alt="whatsapp"
          className="proceed-whatsapp"
          src="./Applicants/whatsapp.png"
        />
      </a>
    </div>
  );
};

export default About;
