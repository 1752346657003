import React from "react";
import NavBar from "./Navbar";
import Home from "./Home/Home";
import Error from "./Error/Error";
import {Route,Switch} from "react-router-dom";
import About from "./About/About"
import Contact from "./Contact/Contact"
import Footer from "./Footer/Footer";
import Product  from "./Products/Product";
import Cart from "./Cart/Cart";
import policy from "./Policies/policy";
import terms from "./Policies/terms";
import thankyou from "./Thanks/thankyou";
import Sorry from "./Apology/Sorry"
import sitemap from "./sitemap";
function App() {
  

  return (
    <div>
      <NavBar className="navigation-bar" />
      <Switch>

        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About}/>
        <Route exact path="/contact" component={Contact}/>
        <Route exact path="/products" component={Product}/>
        <Route exact path="/cart" component={Cart}/>
        <Route exact path="/privacy_policy" component={policy}/>
        <Route exact path="/terms_and_conditions" component={terms}/>
        <Route exact path="/thankyou" component={thankyou}/>
        <Route exact path="/Sorry" component={Sorry}/>
        <Route exact path="/Sitemap" component={sitemap}/>
        <Route component={Error}/>

      </Switch>
      <Footer/>
       
    </div>
  );
}

export default App;
